import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  min_value as rule_min_value,
  max_value as rule_max_value,
  max as rule_max,
} from 'vee-validate/dist/rules'
import pt_BR from 'vee-validate/dist/locale/pt_BR.json'

// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard } from './validators'
import {
  formatarDataWTime,
  formatarValor,
  formatarParaNumber,
  formatarData,
  formatarDataGlobal,
  validarData,
} from '@/libs/utils'
import { log } from 'mathjs'

// export const rule_max_lines = (val, max) => {
//   let valorDigitado = val ? formatarParaNumber(val) : 0
//   let valorMaximo = max.length > 0 ? formatarParaNumber(max[0]) : 0

//   return parseFloat(valorDigitado) <= parseFloat(valorMaximo)
// }

export const rule_valor = (val, max) => {
  let valorDigitado = val ? formatarParaNumber(val) : 0
  let valorMaximo = max.length > 0 ? formatarParaNumber(max[0]) : 0

  return parseFloat(valorDigitado) <= parseFloat(valorMaximo)
}

export const rule_valor_minimo = (val, parametro) => {
  let valorDigitado = val ? formatarParaNumber(val) : 0
  let valorMinimo = parametro.min ? formatarParaNumber(parametro.min) : 0

  return valorDigitado >= valorMinimo
}

export const rule_zero = (value) => {
  if (typeof value != 'string') value = value.toString()
  return value.replace(/[.]/g, '').replace(/[,]/g, '.') > 0
}

export const rule_maior_decimal = (value, maxValue) => {
  const decimalPlaces = value.split(',')[1]?.length || 0
  const parsedValue = parseFloat(value.replace(',', '.'))

  if (parsedValue > maxValue) {
    return false
  }

  if (parsedValue < maxValue) {
    return true
  }

  if (parsedValue === maxValue) {
    return decimalPlaces <= 4
  }

  return true
}

export const rule_parcela = (val, max) => {
  const valorDigitado = val
  const valorMaximo = max[0]
  return parseFloat(valorDigitado) <= parseFloat(valorMaximo)
}

export const rule_camposIguais = (val, otherVal) => {
  return val == otherVal
}

export const rule_dataFutura = (value) => {
  return Number(new Date(value)) < Number(new Date())
}

export const rule_telefone = (value) => {
  const regex = /^[1-9]{2}(?:9[0-9]{8})$/
  return regex.test(String(value).replace(/[^\d]/g, ''))
}

export const rule_celularTelefone = (value) => {
  if ((value.length == 14 || value.length == 16) && value.length > 1) return true
  else return false
}

export const rule_data = (value) => {
  if (value.replace(/[^\d]+/g, '') < 8) return false

  if (validarData(value)) {
    const [dia, mes, ano] = value.split('/').map(Number)
    return Number(new Date(ano, mes - 1, dia)) < Number(new Date())
  } else return false
}

export const rule_cep = (cep) => {
  if (cep === undefined || cep === null) return false
  const cepFormatado = cep.replace(/[^\d]+/g, '')
  return cepFormatado.length === 8
}

export const rule_cnpj = (cnpj) => {
  function validarCNPJ(cnpj) {
    if (cnpj == undefined) return false
    cnpj = cnpj.replace(/[^\d]+/g, '')
    if (cnpj == '') return false
    if (cnpj.length != 14) return false
    // Elimina CNPJs invalidos conhecidos
    else if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false
    else {
      // Valida DVs
      var tamanho = cnpj.length - 2
      var numeros = cnpj.substring(0, tamanho)
      var digitos = cnpj.substring(tamanho)
      var soma = 0
      var pos = tamanho - 7
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (resultado != digitos.charAt(0)) return false
      else {
        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma = 0
        pos = tamanho - 7
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) pos = 9
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
        if (resultado != digitos.charAt(1)) return false
        else return true
      }
    }
  }
  if (cnpj == null || cnpj == undefined || cnpj == '') return true

  return validarCNPJ(cnpj)
}
/**
 * Função para verificar se uma data é igual ou posterior a uma data de referência.
 *
 * @param {string} value - A data a ser verificada, em formato de string.
 * @param {object} context - Um objeto contendo a data de referência.
 * @param {string} context.dataParametro - A data de referência, em formato de string.
 * @returns {boolean} Retorna true se 'value' for igual ou posterior a 'dataParametro'.
 */
export const rule_dataPassada = (value, { dataParametro }) => {
  value = formatarDataGlobal(value)
  dataParametro = formatarDataGlobal(dataParametro)

  const isDataCampoEarlier = value < dataParametro

  // Compara as datas formatadas e retorna true se a data do campo for igual ou posterior à data de referência
  return !isDataCampoEarlier
}

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const min_value = extend('min_value', rule_min_value)

export const max_value = extend('max_value', rule_max_value)

export const max = extend('max', rule_max)

export const maior_decimal = extend('maior_decimal', {
  validate: rule_maior_decimal,
  message: 'Valor informado é maior que o permitido',
})

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const valor = extend('valor', {
  validate: rule_valor,
  message: 'Valor informado é maior que o permitido',
})

export const valor_minimo = extend('valor_minimo', {
  params: ['min'],
  validate: rule_valor_minimo,
  message: (_, { min }) => `O valor precisa ser no mínimo ${formatarValor(min)}.`,
})

export const valor_minimo_number = extend('valor_minimo_number', {
  params: ['min'],
  validate: rule_valor_minimo,
  message: (_, { min }) => `O valor precisa ser no mínimo ${min}.`,
})

export const parcela = extend('parcela', {
  validate: rule_parcela,
  message: 'Parcela informada é maior que o permitido',
})

export const zero = extend('zero', {
  validate: rule_zero,
  message: 'Valor não pode ser 0',
})

export const camposIguais = extend('camposIguais', {
  validate: rule_camposIguais,
  message: 'O campos não são iguais!',
})

export const dataFutura = extend('dataFutura', {
  validate: rule_dataFutura,
  message: 'A data não pode ser futura!',
})

export const telefone = extend('telefone', {
  validate: rule_telefone,
  message: 'Telefone inválido',
})

export const celularTelefone = extend('celularTelefone', {
  validate: rule_celularTelefone,
  message: 'Telefone inválido',
})

export const data = extend('data', {
  validate: rule_data,
  message: 'Data inválida',
})

export const cep = extend('cep', {
  validate: rule_cep,
  message: 'O campo CEP deve ser um campo válido.',
})

export const cnpj = extend('cnpj', {
  validate: rule_cnpj,
  message: 'CNPJ inválido.',
})
/**
 * Extensão de validação para verificar se uma data é passada.
 *
 * Esta função cria uma regra de validação personalizada que pode ser usada em formulários ou outras
 * estruturas de validação de dados para garantir que uma data não seja anterior a uma data específica.
 */
export const dataPassada = extend('dataPassada', {
  // A função de validação que aplica a lógica definida em 'rule_dataPassada'
  validate: rule_dataPassada,

  // Função para gerar uma mensagem de erro personalizada, informando a data mínima aceitável
  message: (_, { dataParametro, stringParametro }) => `A data não pode ser menor que a ${stringParametro}`,

  // Define os parâmetros aceitos pela regra de validação, incluindo a data de referência e uma string descritiva
  params: [
    { name: 'dataParametro', isTarget: false },
    { name: 'stringParametro', isTarget: false },
  ],
})

// Install English and Arabic localizations.
localize({
  pt_BR: {
    messages: pt_BR.messages,
  },
  en: {
    messages: pt_BR.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} é muito curto, você quer ser hackeado?',
      },
    },
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
